@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@charset "UTF-8";


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* min-height: 100vh; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: white;
  height: 43.25%;
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.chevron {
  display: none;
}

.app-name {
  color: black;
  font-family: Raleway;
  font-weight: 600;
  font-size: calc(13px + 2vmin);
}
.app-sub {
  color: black;
  font-family: Raleway;
  font-weight: 400;
  font-size: calc(10px + 2vmin);
}

@media only screen and (min-width: 800px) {
  .chevron {
    display: flex;
    padding-bottom: 1.5em;
    transition: transform 200ms ease-out;
  }

  .chevron:hover {
    transform: scale(1.25);
    transition: transform 200ms ease-out;
    cursor: pointer;
  }

}

/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

